import request from "../../assets/js/request";

// 请求端口
const url = 'https://loaclhost:5002'
/**
 * 查询登录用户
 * @param params
 * @returns {AxiosPromise}
 */
// export function getUser(data) {
//     console.log(data);

//     return request({
//         url: `${url}/Users/userLogin`,
//         method: 'post',
//         data
//     })
// }

/**
 * 查询登录用户
 * @param params
 * @returns {AxiosPromise}
 */
export function getUser(params) {
    console.log(params);

    return request({
        url: `${url}/Values/GetDataAll`,
        method: 'get',
        params
    })
}




/**
 * 编辑用户
 * @param data
 * @returns {AxiosPromise}
 */
// export function editUser(data) {
//     return request({
//         url: `${url}/Users/userLogin`,
//         method: 'post',
//         data
//     })
// }

/**
 * 删除用户
 * @param params
 * @returns {AxiosPromise}
 */
// export function delUser(params) {
//     return request({
//         url: `${url}/Users/userLogin`,
//         method: 'delete',
//         params
//     })
// }

/**

/**
 * 修改用户密码
 * @param data
 * @returns {AxiosPromise}
 */
// export function updatePwd(data) {
//     return request({
//         url: `${url}/Users/userLogin`,
//         method: 'put',
//         data
//     })
// }