// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-people{
    width: 100%;
    height: 80px;
    /* background-color: aqua; */
    padding: 10px;
    display: flex;
}
.ql-people:hover{
    background-color: #ebebeb;
}
.ql-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: bisque;
    margin: 10px 5px 0 5px;

}
.ql-img>img{
    width: 100%;
    height: 100%;
}
.ql-name{
    width: 160px;
    margin: 0 10px;
}
.ql-name>h1{
    margin: 10px 0 0 5px;
    font-size: 15px;
    font-weight: 600;
}
.ql-name>h2{
    margin: 5px 0 0 8px; 
    font-size: 12px;
    color: darkgrey;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/components/communicationLeft.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,wBAAwB;IACxB,sBAAsB;;AAE1B;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,YAAY;IACZ,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".ql-people{\r\n    width: 100%;\r\n    height: 80px;\r\n    /* background-color: aqua; */\r\n    padding: 10px;\r\n    display: flex;\r\n}\r\n.ql-people:hover{\r\n    background-color: #ebebeb;\r\n}\r\n.ql-img{\r\n    width: 50px;\r\n    height: 50px;\r\n    border-radius: 10px;\r\n    background-color: bisque;\r\n    margin: 10px 5px 0 5px;\r\n\r\n}\r\n.ql-img>img{\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n.ql-name{\r\n    width: 160px;\r\n    margin: 0 10px;\r\n}\r\n.ql-name>h1{\r\n    margin: 10px 0 0 5px;\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n}\r\n.ql-name>h2{\r\n    margin: 5px 0 0 8px; \r\n    font-size: 12px;\r\n    color: darkgrey;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
