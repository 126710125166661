/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react"
import { Form, Input, Space, Button, message, Card } from "antd"
import './sign.css'
import axios from "axios"
import { useNavigate } from 'react-router-dom'

const Sign = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    //验证码
    const timerCount = 60 // 默认60秒
    const [count, setCount] = useState(timerCount)
    const timerRef = useRef(null) // 记录时间的定时器
    const cutCount = () => {
        setCount((prevState) => prevState - 1)
    }
    const sendCode = () => {
        // 要发送验证码
        cutCount()
        timerRef.current = setInterval(cutCount, 1000)
    }
    useEffect(() => {
        if (count === 0) {
            clearInterval(timerRef.current) // 清空定时器
            setCount(timerCount) // 重新将技术器设置为60秒
        }
    }, [count])
    //  获取表单信息    
    const getData = (params) => {
        console.log(params);
        if (params.userId != undefined) {
            console.log('aaaaaaaaaaaaaaaaaaaa');
            sendCode()
            getPhoneNum(params)
        } else {
            return message.warning('请输入手机号后发送验证码')
        }
    }
    // 请求验证码
    const getPhoneNum = (params) => {
        axios({
            url: 'https://localhost:7293/Verification/GetVerificationCode',
            params: {
                phoneNum: params.userId,
            },
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            method: 'get',
        }).then(
            res => {
                if (res.status === 200) {
                    message.success('注册成功，正在前往登录页', [1], () => {
                        navigate('/login')
                        console.log(navigate);


                    })
                }

            }
        ).catch(
            res => console.log(res))
    }
    // 完成登录返回登录页面
    const goLogin = () => {
        console.log(form.getFieldsValue())
        const formDate = form.getFieldValue()
        if (formDate.userId == undefined) {
            return message.error('请输入手机号')
        } else if (formDate.phoneNum == undefined) {
            return message.error('请输入验证码')
        } else if (formDate.userName == undefined) {
            return message.error('请输入昵称')
        } else if (formDate.password == undefined) {
            return message.error('请输入密码')
        } else if (formDate.password !== formDate.onpassword) {
            return message.error('俩次输入密码不同，请重新确认密码')
        } else {
            console.log('12333333333333333');

            // 新增用户数据
            // axios({
            //     url: 'https://localhost:7293/Values/PutData',
            //     method: 'post',
            //     data: formDate,
            //     headers: {
            //         'Content-Type': 'application/json; charset=utf-8'
            //     },
            // }.then(
            //     res => {
            //         console.log(
            //             res
            //         );

            //     }
            // ).catch(
            //     res => console.log(res))
            // )
            axios({
                url: 'https://localhost:7293/Values/PutData?tableName=Users',
                data: {
                    userId: formDate.userId,
                    userName: formDate.userName,
                    password: formDate.password
                },
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: 'post',
            }).then(
                res => {
                    if (res.status === 200) {

                    }
                }
            ).catch(
                res => console.log(res))
        }

    }
    return (
        <div>
            <div style={{ backgroundColor: '#063', height: '100px ' }}>
                <img className='main_logo' src={require('../../assets/main_logo.png')} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 100px)' }}>

                <Card style={{ width: '500px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <a href='/login' style={{ fontSize: '12px', color: '#063', marginRight: '10px' }}>已有账号去登录...</a>
                    </div>
                    <Form onFinish={(params) => { getData(params) }} form={form}>
                        <Form.Item
                            label="手&nbsp;&nbsp;机&nbsp;&nbsp;号"
                            name="userId">
                            <Input placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            label="验&nbsp;&nbsp;证&nbsp;&nbsp;码"
                            name="phoneNum"
                        >
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input placeholder="请输入验证码" />
                                <Button
                                    type="primary"
                                    disabled={count < timerCount}
                                    htmlType="submit"
                                    style={{ backgroundColor: '#063', color: 'white' }}
                                >
                                    {count === timerCount ? "发送验证码" : `还剩${count}秒`}
                                </Button>
                            </Space.Compact>
                            {/* <Button onClick={stepItem}>
                            下一步
                        </Button> */}
                        </Form.Item>
                        <Form.Item
                            label="昵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称"
                            name="userName">
                            <Input placeholder="请输入昵称" />
                        </Form.Item>
                        <Form.Item
                            label="密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码"
                            name="password">
                            <Input.Password placeholder="请输入密码" />
                        </Form.Item>
                        <Form.Item
                            label="确认密码"
                            name="onpassword">
                            <Input.Password placeholder="确认密码" />

                        </Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <Button style={{ backgroundColor: '#063' }} onClick={goLogin} type="primary">确认注册</Button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default Sign