/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Button, Layout, Dropdown, Avatar } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
// import { collapseMenu } from '../../store/reducers/tab'
import { useNavigate } from 'react-router-dom'
import './commonHeader.css'


const { Header } = Layout

const CommonHeader = ({ collapsed }) => {
    collapsed = true
    
    const setCollapsed = () => {
        // dispatch(collapseMenu())
    }
    const navigate = useNavigate()
    const items = [
        {
            key: '1',
            label: ( 
                <a target="_blank" rel="noopener noreferrer">
                    个人中心
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={() => logout(!collapsed)} target="_blank" rel="noopener noreferrer" >
                    退出
                </a>
            ),
        }
    ]
    // 登出
    const logout = () => {
        localStorage.removeItem('token')
        navigate('/login')
    }
    return (
        <Header className="header-container">
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    fontSize: '16px',
                    width: 64,
                    height: 32,
                    backgroundColor: '#fff'
                }}
            />
            <Dropdown
                menu={{ items }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Avatar size={36} src={<img src={require("../../assets/login_logo.png")} />} />
                </a>
            </Dropdown>
        </Header>
    )
}

export default CommonHeader