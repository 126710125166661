// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.communication{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.communication_left{
    width: 20%;
    height: 100%;
    margin-right: 3px;
    background-color: #efefef;
}
.communication_right{
    width: 80%;
    height: 100%;
}
.communication_right_top{
    width: 100%;
    height: 70%;
    /* background-color: blueviolet; */
}
.communication_right_bottom{
    width: 100%;
    height: 30%;
    background-color: #ffffff;
}

`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI,WAAW;IACX,WAAW;IACX,kCAAkC;AACtC;AACA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;AAC7B","sourcesContent":[".communication{\r\n    width: 100%;\r\n    height: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n.communication_left{\r\n    width: 20%;\r\n    height: 100%;\r\n    margin-right: 3px;\r\n    background-color: #efefef;\r\n}\r\n.communication_right{\r\n    width: 80%;\r\n    height: 100%;\r\n}\r\n.communication_right_top{\r\n    width: 100%;\r\n    height: 70%;\r\n    /* background-color: blueviolet; */\r\n}\r\n.communication_right_bottom{\r\n    width: 100%;\r\n    height: 30%;\r\n    background-color: #ffffff;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
