import React, { Component } from 'react'
import {
  RouterProvider,
} from "react-router-dom";
import router from './router'

export class App extends Component {
  render() {
    return (
      <div className="App">
        <RouterProvider router={router} />
      </div>
    )
  }
}

export default App