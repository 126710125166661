/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Col, Row, Form, Input, Button, message } from 'antd'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../api/Robot/login'

const App = () => {

  const navigate = useNavigate()

  const getData = (params) => {
    console.log(params)
    // if (params.username == undefined || params.password == undefined) {
    //   return message.warning('请输入账号或密码')
    // } else {
      getUser({
        tableName: 'Users',
        // userId: params.username,
        // password: params.password
      }).then(res => {
        console.log(res,'successres');
        
        // if (res.status === 200) {
        //   message.success('登录成功', [1], () => {
        //     navigate('/home')
        //   })
        // }
      }
      ).catch(err => console.log('errr', err)
      )
    // }
  }

  return (
    <div className='Login_bc'>
      <Row>
        <Col flex={2}>
          <div className='container_logo'>
            <img className='main_logo' src={require('../../assets/main_logo.png')} />
            <img className='Login_logo' src={require('../../assets/login_logo.png')} />
          </div>
        </Col>
        <Col flex={3} className='Login_form'>
          <Form
            className="login-container"
            // 可以获取登录的信息
            onFinish={(params) => { getData(params) }}
          >
            <div className="login_title">系统登录</div>
            <Form.Item
              label="账号"
              name="username"
            >
              <Input placeholder="请输入账号" />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
            >
              <Input.Password placeholder="请输入账号" />
            </Form.Item>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <a href='/signin' style={{ fontSize: '12px', color: '#063', marginRight: '10px' }}>去注册...</a>
            </div>
            <Form.Item className="login-button">
              <Button className='Login_button' type="primary" htmlType="submit">登录</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div >
  );
};
export default App;