// eslint-disable-next-line import/no-anonymous-default-export
export default  [
    {
        path: '/home',
        name: 'home',
        label: '客户互动中心',
        icon: 'TeamOutlined',
        url: '/home'
    },
    {
        path: '/robot',
        name: 'robot',
        label: '智能客服机器人',
        icon: 'ShopOutlined',
        url: '/robot'
    },
    {
        path: '/user',
        name: 'user',
        label: '自动应答设置',
        icon: 'UserOutlined',
        url: '/user/index'
    },
    {
        path: '/qwer',
        name: 'qwer',
        label: '语料库管理',
        icon: 'UserOutlined',
        url: '/user/index'
    },
    {
        path: '/rewq',
        name: 'rewq',
        label: '人工客服管理',
        icon: 'UserOutlined',
        url: '/user/index'
    },
    {
        path: '/qwe',
        name: 'qwe',
        label: '客服分配',
        icon: 'UserOutlined',
        url: '/user/index'
    },
   
]