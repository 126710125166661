import { createBrowserRouter, Navigate } from 'react-router-dom'
import Main from '../pages/main'
import Home from '../pages/home'
import Login from '../pages/login'
import Sign from '../pages/Sign'
import Robot from '../pages/Robot'
// import User from '../pages/user'
const routes = [
    {
        path: '/',
        Component: Main,
        children: [
            {
                // 初始页面
                path: '/',
                element: <Navigate to="home" replace />
            },
            {
                //首页
                path: 'home',
                Component: Home,
            },
            {
                // 智能客服机器人
                path: '/robot',
                Component: Robot
            },
            // {
            //     path:'/user',
            //     Component:User
            // }
        ]
    },
    {
        // 登录页
        path: '/login',
        Component: Login
    },

    {
        // 注册
        path: '/signin',
        Component: Sign
    }
]
export default createBrowserRouter(routes)