import React, { Component } from 'react'
// 头部
// import CommonTag from '../components/commonTag';
// 内容区
import CommonHeader from '../components/commonHeader'
// 侧边导航栏
import CommonAside from '../components/commonAside';
import { Outlet } from 'react-router-dom';
import '../assets/css/Common.css'
import { Layout } from 'antd'
const { Content } = Layout

export class Main extends Component {
  render() {
    return (
      <Layout className="main-container">
        <CommonAside />
        <Layout>
          <CommonHeader />
          {/* <CommonTag /> */}
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default Main