/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Empty, Input, Modal, Table, Tooltip } from "antd";
import { useCallback, useEffect, useState, useMemo } from "react";
// import {delRobot, enabledRobot, getRobotList} from "../../api/Robot/sysRobot";
// import {errorMsg, infoMsg, successMsg} from "../../assets/js/message";
import ModalContext from "../../assets/js/context";
import EditRobot from "./EditRobot";
import MyPagination from "../../components/MyPagination";


const Index = () => {

    //  定义查询参数
    const [blurry, setBlurry] = useState(null)

    //  定义源数据
    const [dataSource, setDataSource] = useState([])

    //  编辑弹窗控制
    const [openModal, setOpenModal] = useState(false)

    //  定义一个用户对象,用于编辑时传入子组件中
    const [RobotObj, setRobotObj] = useState({})

    //  定义分页参数
    //  总条数
    const [total, setTotal] = useState(0)
    //  当面页码
    const [currentPage, setCurrentPage] = useState(1)
    //  每页条数
    const [pageSize, setPageSize] = useState(10)
    const [arrow, setArrow] = useState('Show');
    //  获取列表
    const getRobotTable = () => {
        const params = {
            blurry: blurry,
            size: pageSize,
            currentPage: currentPage
        }
        console.log('[a', params);

        // getRobotList(params).then(res => {
        //     if (res.success) {
        //         setDataSource(res.data.records)
        //         setTotal(res.data.total)
        //     } else {
        //         errorMsg(res.msg)
        //     }
        // })
    }


    const columns = [
        {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '内容',
            dataIndex: 'age',
            key: 'age',
            render: () => <Tooltip placement="topRight" title={'text'} arrow={mergedArrow}>
                <Button>TR</Button>
            </Tooltip>,
        },

        {
            title: '操作',
            // dataIndex: 'address',
            // key: 'address',
            render: () => {

            }
        },
    ];

    //  查询框输入
    const changeValue = (e) => {
        setBlurry(e.target.value)
    }


    //  编辑
    const editRobot = (data) => {
        setOpenModal(true)
        setRobotObj(data)
    }

    //  删除


    //  子组件调用父组件方法
    const handleChild = useCallback(() => {
        getRobotTable()
    }, [pageSize, currentPage])

    // useEffect(() => {
    //     getRobotTable()
    // }, [])

    console.log(dataSource, 'dadddddddddddddddddddddddddddddddddddddddddddddddd');
    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true,
        };
    }, [arrow]);
    const tablenodata = {
        emptyText: () => (

            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />

        )
    }
    return (
        <>
            <div className={'search-div'}>
                <Input
                    className={'search-input'}
                    value={blurry}
                    placeholder={'请输入标题进行查询'}
                    onChange={changeValue}
                    allowClear={true}
                ></Input>
                <Button
                    type={"primary"}
                    onClick={getRobotTable}
                >查询</Button>
                <Button
                    className={'add-btn'}
                    onClick={editRobot}
                >新增</Button>
            </div>
            <Table dataSource={dataSource} columns={columns} locale={tablenodata} />
            {/*分页*/}
            {dataSource.length > 0 ? <ModalContext.Provider value={{ pageSize, setPageSize, currentPage, setCurrentPage, total, setTotal }}>
                <MyPagination getList={handleChild}></MyPagination>
            </ModalContext.Provider> : ''}
            {/*编辑Modal*/}
            <ModalContext.Provider value={{ openModal, setOpenModal, RobotObj }}>
                <EditRobot getList={handleChild}></EditRobot>
            </ModalContext.Provider>
        </>
    )
}

export default Index