/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Menu, Layout } from 'antd'
import * as Icon from "@ant-design/icons"
import { useNavigate } from 'react-router-dom'
import MenuConfig from '../../config'
import { useDispatch } from "react-redux"
import { selectMenuList } from '../../store/reducers/tab'
import './commonAside.css'

const { Sider } = Layout
const iconToElement = (name) => React.createElement(Icon[name]);
const items = MenuConfig.map((icon) => {
    const child = {
        key: `${icon.path}`,
        icon: iconToElement(icon.icon),
        label: `${icon.label}`
    }
    if (icon.children) {
        child.children = icon.children.map(item => {
            return {
                key: item.path,
                label: item.label
            }
        })
    }
    return child
})
const CommonAside = ({ collapsed }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // 添加数据到store
    const setTabsList = (val) => {
        dispatch(selectMenuList(val))
    }
    // 点击菜单
    const selectMenu = (e) => {
        let data
        MenuConfig.forEach((item) => {
            // 找到当前的数据
            if (item.path === e.keyPath[e.keyPath.length - 1]) {
                data = item
                // 如果是有二级菜单
                if (e.keyPath.length > 1) {
                    data = item.children.find((child) => {
                        return child.path === e.key
                    })
                }
            }
        })
        setTabsList({
            path: data.path,
            name: data.name,
            label: data.label
        })
        // 页面跳转
        navigate(e.key)
    }
    return (
        <Sider
            width={200}
            style={{
                background: '#fff',
            }}
        >
            <div className='Aside_logo_div'>
                <img className='Aside_logo' src={require('../../assets/main_logo.png')} />
            </div>
            <div className='Aside_logo_ele'>
                <img src={require('../../assets/logo.png')} />
            </div>
            <Menu mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{
                    borderRight: 0,
                    backgroundColor: '#fff',
                    color: '#000'
                }}
                onClick={selectMenu}
                items={items}>
            </Menu>
        </Sider>
    )
}

export default CommonAside