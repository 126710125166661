// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-div {
    padding-bottom: 10px;
    padding-top: 10px;
}

.search-div .ant-btn {
    margin-left: 20px;
}

.search-div .add-btn {
    float: right;
}

.search-input {
    width: 200px;
}

.ant-tabs {
    height: 100%;
}

.ant-tabs-content-holder {
    padding: 0 20px;
}

.ant-table-cell .ant-btn {
    margin: 0 5px;
}

.success {
    background: #52c41a;
}

.ant-form-item .ant-form-item-label {
    width: 100px;
}

/**
默认配置全局modal样式
 */
.edit-modal .ant-modal-body {
    max-height: 500px;
    min-height: 300px;
    overflow-y: auto;
    padding: 20px 30px;
}


/**
默认将所有modal的底部按钮居中
 */
.ant-modal-footer {
    text-align: center !important;
}

/**
默认配置全局带分页table高度
 */
.page-table .ant-table-container {
    height: calc(100vh - 200px);
    border-inline-end-color: rgb(52, 57, 59);
    border-inline-end: 1px solid #f0f0f0;
    border-bottom-color: rgb(52, 57, 59);
    border-bottom: 1px solid #f0f0f0;
}

/**
默认配置全局不带分页table高度
 */
.no-page-table .ant-table-container {
    height: calc(100vh - 180px);
    border-inline-end-color: rgb(52, 57, 59);
    border-inline-end: 1px solid #f0f0f0;
    border-bottom-color: rgb(52, 57, 59);
    border-bottom: 1px solid #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Common.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;;EAEE;AACF;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;;AAGA;;EAEE;AACF;IACI,6BAA6B;AACjC;;AAEA;;EAEE;AACF;IACI,2BAA2B;IAC3B,wCAAwC;IACxC,oCAAoC;IACpC,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;;EAEE;AACF;IACI,2BAA2B;IAC3B,wCAAwC;IACxC,oCAAoC;IACpC,oCAAoC;IACpC,gCAAgC;AACpC","sourcesContent":[".search-div {\n    padding-bottom: 10px;\n    padding-top: 10px;\n}\n\n.search-div .ant-btn {\n    margin-left: 20px;\n}\n\n.search-div .add-btn {\n    float: right;\n}\n\n.search-input {\n    width: 200px;\n}\n\n.ant-tabs {\n    height: 100%;\n}\n\n.ant-tabs-content-holder {\n    padding: 0 20px;\n}\n\n.ant-table-cell .ant-btn {\n    margin: 0 5px;\n}\n\n.success {\n    background: #52c41a;\n}\n\n.ant-form-item .ant-form-item-label {\n    width: 100px;\n}\n\n/**\n默认配置全局modal样式\n */\n.edit-modal .ant-modal-body {\n    max-height: 500px;\n    min-height: 300px;\n    overflow-y: auto;\n    padding: 20px 30px;\n}\n\n\n/**\n默认将所有modal的底部按钮居中\n */\n.ant-modal-footer {\n    text-align: center !important;\n}\n\n/**\n默认配置全局带分页table高度\n */\n.page-table .ant-table-container {\n    height: calc(100vh - 200px);\n    border-inline-end-color: rgb(52, 57, 59);\n    border-inline-end: 1px solid #f0f0f0;\n    border-bottom-color: rgb(52, 57, 59);\n    border-bottom: 1px solid #f0f0f0;\n}\n\n/**\n默认配置全局不带分页table高度\n */\n.no-page-table .ant-table-container {\n    height: calc(100vh - 180px);\n    border-inline-end-color: rgb(52, 57, 59);\n    border-inline-end: 1px solid #f0f0f0;\n    border-bottom-color: rgb(52, 57, 59);\n    border-bottom: 1px solid #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
