import { Button, Form, Input, Modal, Select } from "antd";
import { useContext, useState } from "react";
import ModalContext from "../../assets/js/context";
// import {getRoleList} from "../../api/role/sysRole";
import { errorMsg, successMsg } from "../../assets/js/message";
import { editRobot } from "../../api/Robot/sysRobot";
const { TextArea } = Input;
const EditRobot = ({ getList }) => {

    const { openModal, setOpenModal, RobotObj } = useContext(ModalContext)

    const [title, setTitle] = useState('新增用户')

    const [loading, setLoading] = useState(false)

    const [roleData, setRoleData] = useState([])

    const [roleIds, setRoleIds] = useState([])

    const [form] = Form.useForm()

    //  弹窗打开后执行
    // const afterOpenChange = (open) => {
    //     setTitle('新增用户')
    //     setLoading(false)
    //     setRoleIds([])
    //     form.resetFields()
    //     if (open && RobotObj && RobotObj.id) {
    //         setTitle('编辑用户')
    //         //  获取角色ID数组字符串
    //         const roleIdStr = RobotObj.roleIds[0]
    //         if (roleIdStr){
    //             //  将字符串转为数字,以匹配角色列表的ID
    //             RobotObj.roleIds = roleIdStr.split(',').map(Number)
    //         }
    //         form.setFieldsValue(RobotObj)
    //     }
    //     getRoleListFun()
    // }

    //  获取当前用户所有的角色权限
    // const getRoleListFun = () => {
    //     getRoleList({}).then(res => {
    //         if (res.success) {
    //             setRoleData(res.data)
    //         } else {
    //             errorMsg(res.msg)
    //         }
    //     })
    // }

    //  提交
    const onFinish = (values) => {
        // setLoading(true)
        const formData = { ...values }
        // editRobot(formData).then(res => {
        //     if (res.success){
        //         successMsg(res.data)
        //         //  调用父页面方法
        //         getList()
        //  关闭弹窗
        setOpenModal(false)
        // } else {
        //     errorMsg(res.msg)
        // }
        // })
        // setLoading(false)
    }
    const onChange = (e) => {
        console.log('Change:', e.target.value);
    };

    return (
        <>
            <Modal
                title={title}
                className={'edit-modal'}
                width={'50%'}
                open={openModal}
                // afterOpenChange={afterOpenChange}
                maskClosable={false}
                onCancel={() => setOpenModal(false)}
                footer={[
                    <Button key={'reset'} onClick={() => form.resetFields()}>重置</Button>,
                    <Button key={'ok'} type={"primary"} loading={loading} onClick={() => form.submit()}>确认</Button>,
                ]}
                transitionName={'ant-fade'}
            >
                <Form form={form} name={'editRobot'} onFinish={onFinish} autoComplete={'off'} labelCol={{ span: 2 }}>
                    <Form.Item name={'id'} hidden={true}>
                        <Input></Input>
                    </Form.Item>
                    <Form.Item name={'Robotname'} label={'标题'} rules={[{ required: true }]}>
                        <Input placeholder={'请输入标题'}></Input>
                    </Form.Item>
                    <Form.Item name={'nickName'} label={'内容'} rules={[{ required: true }]}>
                        <TextArea
                            showCount
                            // maxLength={1000}
                            onChange={onChange}
                            placeholder="输入内容"
                            style={{
                                height: 300,
                                resize: 'none',
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default EditRobot