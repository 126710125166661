import React from "react"
import './home.css'
import PersonMessage from "./components/PersonMessage"
import CommunicationLeft from "./components/communicationLeft"
const Home = () => {
  return (
    <div className="communication">
        <div className="communication_left">
          <CommunicationLeft/>
        </div>
        <div className="communication_right">
          <div className="communication_right_top">bass</div>
          <div className="communication_right_bottom">
            <PersonMessage/>
          </div>
        </div>
    </div>
  )
}
export default Home