import React from 'react'
import './communicationLeft.css'
import { Tabs, Input } from 'antd'

const communicationLeft = () => {

    const onclick = () => {
console.log('213');

    }
    return (
        <div>
            <div className='ql-search'>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input
                        placeholder="搜索"
                        style={{ margin: '10px 0', width: '95%', borderRadius: '50px' }}
                        prefix={
                            <svg style={{ width: '10px', height: '10px' }} t="1728372168877" className="icon" viewBox="0 0 1059 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1557" width="200" height="200"><path d="M463.65500237 773.18500475a352.19666903 352.19666903 0 1 1 352.19666903-352.19666904 352.57094797 352.57094797 0 0 1-352.19666904 352.19666904z m0-641.95110188a289.81681265 289.81681265 0 1 0 289.81681265 289.81681266A290.12871176 290.12871176 0 0 0 463.65500237 131.23390287z" p-id="1558"></path><path d="M952.6506972 912.47922422a31.18992819 31.18992819 0 0 1-21.77057017-8.85793963L679.61406566 657.96941048a31.18992819 31.18992819 0 0 1 43.66589914-44.60159729l251.26606138 245.65187411a31.18992819 31.18992819 0 0 1-21.83294917 53.52191674z" p-id="1559"></path></svg>
                        }

                    />
                </div>
            </div>
            <Tabs style={{ marginLeft: '20px' }}>

                <Tabs.TabPane tab="聊天" key="1" onclick={onclick()}>
                    <div className='ql-people'>
                        <div className='ql-img'>
                            {/* <img  src={require('../../../assets/logo.png')} /> */}
                        </div>
                        <div className='ql-name'>
                            <h1>名字</h1>
                            <h2>内容</h2>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="联系人" key="2">2</Tabs.TabPane>
            </Tabs>

        </div>
    )
}
export default communicationLeft