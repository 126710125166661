import request from "../../assets/js/request";
const url = 'https://localhost:7293'

/**
 * 获取知识库列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getRobotList(params) {
    return request({
        url: `${url}/Users/userLogin`,
        method: 'get',
        params
    })
}
/****
 * 查询
 * @param params
 * @returns {AxiosPromise}
 * */
export function UpdateRobot(data) {
    return request({
        url: `${url}/Users/userLogin`,
        method: 'get',
        data
    })
}

/****
 * 修改
 * @param params
 * @returns {AxiosPromise}
 * */
export function DetailRobot(data) {
    return request({
        url: `${url}/Users/userLogin`,
        method: 'post',
        data
    })
}

/***
 * 删除
 * @param params
 * @returns {AxiosPromise}
 * * */
export function DelRobot(params) {
    return request({
        url: `${url}/Users/userLogin`,
        method: 'delete',
        params
    })
}