import React, { useState } from 'react';
import Picker from 'react-emoji';
import { Button, Input } from 'antd';
import './PersonMessage.css'
const { TextArea } = Input;
const ChatApp = () => {
    const [message, setMessage] = useState('');
    const [showPicker, setShowPicker] = useState(false);

    const onEmojiClick = (event, emojiObject) => {
        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', message);

        setMessage(message + emojiObject.emoji);
    };

    return (
        <div>
            <div className='ql-emoji'>表情栏</div>
            <div className='ql-container' >
                <TextArea className='ql-textarea custom-textarea' rows={6}/>
            </div>
            <div className='ql-send'>
                <Button className='ql-button'>发送 </Button>
            </div>
        </div>
    );
};

export default ChatApp;